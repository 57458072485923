import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      component: () => import("@/views/dashboard/login/index"),
    },
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      redirect: "/Chat",
      children: [
        {
          name: "Chat",
          path: "/Chat",
          component: () => import("@/views/dashboard/chat"),
        },
      ],
    },
  ],
});
