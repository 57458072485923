import store from "@/store";
import { getToken } from "@/plugins/utils";
import Cookies from "js-cookie";

const globalToken = getToken();
let ws = null;
let timer = null;

// 获取 websocket 推送的数据
const websocketonmessage = (e) => {
  store.commit("SET_SOCKETMSG", e.data);
};

// 连接成功
const websocketonopen = () => {
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
  store.commit("SET_WS_TIMEOUTCLOSE", false);
  console.log("连接websocket成功");
};

// 连接失败时重新连接
const websocketonerror = () => {
  //   reconnect()
};

// 断开链接后报错
const websocketclose = (e) => {
  console.log("websocket 断开: " + e.code + " " + e.reason + " " + e.wasClean);
  if (!store.state.wsTimeoutClose) {
    timer = setTimeout(() => {
      reconnect();
      console.log(`正在尝试重连....`);
    }, 5000);
  }
};

// 手动关闭 websocket
const closewebsocket = () => {
  if (ws) {
    ws.close();
    ws = null;
  }
};

const reconnect = async () => {
  if (getToken()) {
    initWebSocket();
  }
};

// 发送数据
const sendData = (data, callback) => {
  ws.send(data);
};

// 初始化
const initWebSocket = (tokenStr) => {
  // 登陆完初始化ws
  const wsurl = `ws://${
    process.env.VUE_APP_GPT_WS_API
  }/chatGpt/chat/${Cookies.get("gpt_uid")}`;
  ws = new WebSocket(wsurl);
  ws.onmessage = websocketonmessage;
  ws.onopen = websocketonopen;
  ws.onerror = websocketonerror;
  ws.onclose = websocketclose;
};

// 刷新页面初始化ws
if (globalToken) initWebSocket();

export { initWebSocket, sendData, closewebsocket, reconnect };
