import axios from "axios";
import store from "@/store";
import { getToken } from "@/plugins/utils";

const service = axios.create({
  baseURL: process.env.BASE_API, // api的base_url
  timeout: 1200000, // request timeout
});

service.interceptors.request.use(
  (config) => {
    config.headers["token"] = getToken() || '';

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // 处理网络错误
    if (response.status !== 200 && response.status !== 302) {
      alert(`请求状态异常:status= ${response.status}`);
    }
    const res = response.data;
    // 处理业务错误
    if (res.code !== "g0000101") {
      let codeMessage = "";

      codeMessage = res.msg;

      alert(codeMessage)


      // 登录过期 跳转到登录页面
      if (res.code === "g00000001") {
        store.dispatch("lgoOutFun").then(() => {
          setTimeout(function () {
            window.location.href = "/login";
          }, 1000);
        });
      }

      return Promise.reject(codeMessage);
    } else {
      return res.data;
    }
  },
  (error) => {
    alert(error.message)
    return Promise.reject(error);
  }
);

export default service;
