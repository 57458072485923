import request from "@/plugins/request";

export function gptLogin(params) {
  return request({
    url: `/system/gptLogin`,
    method: "POST",
    params,
  });
}
//   新增、保存或修改GPT话题
export function saveOrUpdateGptTopic(data) {
  return request({
    url: `/chatGpt/saveOrUpdateGptTopic`,
    method: "POST",
    data: data,
  });
}

// 获取GPT会话列表
export function gptTopicList(params) {
  return request({
    url: `/chatGpt/gptTopicList`,
    method: "POST",
    params,
  });
}

// 获取GPT聊天列表
export function gptQaInfoList(params) {
  return request({
    url: `/chatGpt/gptQaInfoList`,
    method: "POST",
    params,
  });
}

// GPT聊天
export function chat(data) {
  return request({
    url: `/chatGpt/chat`,
    method: "POST",
    data: data,
  });
}

// 删除GPT话题
export function deleteGptTopic(params) {
  return request({
    url: `/chatGpt/deleteGptTopic`,
    method: "POST",
    params,
  });
}
