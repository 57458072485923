import Vue from "vue";
import Vuex from "vuex";
import { removeToken, setToken } from "@/plugins/utils";
import { gptLogin, gptQaInfoList } from "@/api";
import { initWebSocket, closewebsocket } from "@/plugins/socket";
import Cookies from "js-cookie";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:"",
    drawer: null,
    session: {},
    record: {},
    isChating: false,
    wsTimeoutClose: false,
    socketMsg: "",
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_SESSION(state, payload) {
      state.session = payload;
    },
    SET_RECORD(state, payload) {
      state.record = payload;
    },
    SET_ISCHATING(state, payload) {
      state.isChating = payload;
    },
    SET_WS_TIMEOUTCLOSE(state, payload) {
      state.wsTimeoutClose = payload;
    },
    SET_SOCKETMSG(state, payload) {
      state.socketMsg = payload;
    },
  },
  actions: {
    gptQaInfoList({ commit }) {
      return new Promise((resolve, reject) => {
        gptQaInfoList({})
          .then((res) => {
            commit("SET_RECORD", res);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    lgoOutFun({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_TOKEN", "");
        Cookies.remove("gpt_userName");
        Cookies.remove("gpt_uid");
        closewebsocket();
        removeToken();
        resolve();
      });
    },
    LoginByUsername({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        gptLogin(userInfo)
          .then((res) => {
            commit("SET_TOKEN", res.token);
            setToken(res.token);
            Cookies.set("gpt_userName", res.userName);
            Cookies.set("gpt_uid", res.id);
            initWebSocket();
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
