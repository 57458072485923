import Cookies from "js-cookie";

const TokenKey = "gpt_token";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token, id) {
  return (() => {
    Cookies.set(TokenKey, token);
  })();
}

export function removeToken() {
  return (() => {
    Cookies.remove(TokenKey);
  })();
}

// 截取代码并生成 hlighlight 代码片段
export function parseAndHighlightCode(text) {
  text = String(text);
  const regex = /```(\w+)?\s*([\s\S]*?)```/g;
  return text.replace(regex, (match, language, code) => {
    const langClass = language ? ` class="${language}"` : "";
    return `<div class="code-title"><span>${
      language || ""
    }</span><span class="copy-code">复制代码</span></div><pre><code${langClass}>${code.trim()}</code></pre>`;
  });
}

// 截取代码
export function parseCode(text) {
  text = String(text); // Ensure 'text' is a string
  const regex = /```(\w+)?\s*([\s\S]*?)```/g;
  return text.match(regex)[0].replace(/```/g, "");
}
