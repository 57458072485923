import router from "./router";
import { getToken } from "@/plugins/utils";

router.beforeEach((to, from, next) => {
  const islogin = getToken();
  if (to.path === "/login") {
    if (islogin) {
      next("/");
    } else {
      next();
    }
  } else {
    if (islogin) {
      next();
    } else {
      next("/login");
    }
  }
});

router.afterEach(() => {});
